<template>
    
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">
            <el-form :inline="true">
                <el-form-item label="订单号：">
                    <el-input v-model="parent_order_sn" placeholder="请输入订单号"></el-input>
                </el-form-item>
                <el-form-item label="user_id：">
                    <el-input v-model="uid" placeholder="请输入user_id"></el-input>
                </el-form-item>
                <el-form-item label="商品id：">
                    <el-input v-model="item_id" placeholder="itemid"></el-input>
                </el-form-item>
                <el-form-item label="下单开始时间：">
                    <el-date-picker
                    v-model="order_pay_time_start"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="下单开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="下单结束时间：">
                    <el-date-picker
                    v-model="order_pay_time_end"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="下单结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="子订单号：">
                    <el-input v-model="order_sn" placeholder="子订单号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="seach(1)">搜索</el-button></el-button> 
                </el-form-item>
            </el-form>
        </div>
        <el-table
            v-loading="loading"
            :data="orderList"
            border
            >
            <el-table-column
                fixed
                prop="order_id"
                label="编号"
                width="80px"
            >
            </el-table-column>
            <el-table-column
                fixed
                prop="parent_order_sn"
                label="订单号"
                width="180px"
            >
            </el-table-column>
            <el-table-column
                label="user_id（user_key）"
                width="250px"
            >
            <template slot-scope="scope">
                {{scope.row.user_id}}({{scope.row.user_key}})
            </template>
</el-table-column>
<el-table-column prop="item_title" label="商品标题" width="200"></el-table-column>
<el-table-column prop="item_id" label="商品ID" width="120"></el-table-column>

<el-table-column prop="platform_commission" label="淘宝佣金" width="80"></el-table-column>
<el-table-column prop="user_commission" label="用户佣金" width="80"></el-table-column>
<el-table-column prop="platform_commission_rate" label="佣金比例" width="80"></el-table-column>
<el-table-column prop="order_time | dateTime" label="下单时间" width="100">
    <template slot-scope="scope">
        {{scope.row.order_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="结算时间" width="100">
    <template slot-scope="scope">
        {{scope.row.user_settled_time | dateTime}}
    </template>
</el-table-column>
<!-- <el-table-column label="订单状态">
    <template slot-scope="scope">
        <span :class="scope.row.status == 1?'green':scope.row.status == 3?'blue':scope.row.status==4?'warn': scope.row.status == 5||scope.row.status==6||scope.row.status==7?'red':''">
            {{scope.row.status | status}}
        </span>
    </template>
</el-table-column> -->
<el-table-column prop="pay_amount" label="付款金额" width="80"></el-table-column>
<el-table-column prop="order_sn" label="子订单号" width="180"></el-table-column>
<el-table-column prop="relation_id" label="渠道ID" width="100"></el-table-column>
<el-table-column label="订单类型" width="80">
    <template slot-scope="scope">
       {{scope.row.order_type | orderType}}
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>
</section>
<!-- /.content -->
</template>

<script>
    let common = JSON.parse(window.localStorage.getItem('common'))
    import * as api from '@/config/api'
    export default {
        name: 'selectionOrder',
        components: {},
        data() {
            return {
                orderList: [],
                total: 0,
                page: 1,
                pagesize: 10,
                payStatusList: common.order_status_list,
                uid: '',
                parent_order_sn: '',
                order_sn: '',
                item_id: '',
                order_pay_time_start: '',
                order_pay_time_end: '',

                dialog: false,

                loading: true,
                order_id: '',
                unionid: '',
                user_commission: ''
            }
        },
        watch: {},
        filters: {
            status(val) {
                let name = ''
                if (JSON.stringify(common.order_status_list).indexOf(val) != -1) {
                    common.order_status_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }

                return name
            },
            orderType(val) {
                let name = ''
                if (JSON.stringify(common.order_type_list).indexOf(val) != -1) {
                    common.order_type_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getSelectOrder()
        },
        methods: {
            close() {
                this.show = false
                this.getSelectOrder()
            },
            getSelectOrder(page, size) {
                this.loading = true
                api.getSelectOrder({
                    user_id: this.uid,
                    order_sn: this.order_sn,
                    parent_order_sn: this.parent_order_sn,
                    item_id: this.item_id,
                    order_pay_time_start: this.order_pay_time_start,
                    order_pay_time_end: this.order_pay_time_end,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.orderList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getSelectOrder()
            },
            currentChange(val) {
                this.page = val
                this.getSelectOrder()
            },
            seach(page, size) {
                this.getSelectOrder(page, size)
            },
            alterDialog(id, uid, commission) {
                this.order_id = id
                this.unionid = uid
                this.user_commission = commission
                this.dialog = true
            },
            alter() {
                api.alterOrder({
                    'order_id': this.order_id,
                    'user_id': this.unionid,
                    'user_commission': this.user_commission,
                }, res => {
                    console.log(res)
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })

                }, err => {
                    console.log(err)
                })
            },
            settlement(id) {
                this.$confirm('请确定是否结算该订单', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.settlementOrder({
                        order_id: id
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: '结算成功!'
                        });
                        this.getSelectOrder()
                    })
                }).catch(() => {})
            }
        }
    }
</script>
<style scoped>
    .el-table .cell {
        text-align: center;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    img {
        width: 150px;
    }
    
    .red {
        color: #F56C6C
    }
    
    .blue {
        color: #409EFF
    }
    
    .green {
        color: #67C23A
    }
    
    .warn {
        color: #E6A23C
    }
</style>